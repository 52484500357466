import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '../Landing.styles'
import familiesBg from './families-bg.jpg'

export const FamiliesStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    background-image: url(${familiesBg});
    background-size: cover;
    background-position: center center;
    color: ${theme.colors.white};
    position: relative;
    padding: ${theme.spacing(12, 0)};

    .section-inner {
      display: flex;
      justify-content: flex-end;

      ${theme.breakpoints.down('md')} {
        justify-content: center;
      }
    }

    .bg-overlay {
      z-index: 0;
      ${theme.mixins.engulfAbsolute}
      background: ${theme.colors.black};
      opacity: 0.5;
    }
    .title {
      line-height: 50px;
    }

    .viimsi-logo {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 1;

      ${theme.breakpoints.down('md')} {
        display: none;
      }
    }

    .text-container {
      z-index: 1;
      max-width: 700px;
      width: 95%;
    }
  `
)
