import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '@pages/Customer/Landing/Landing.styles'

export const ContactStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    padding: ${theme.spacing(18, 0)};

    .developer-column {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      ${theme.breakpoints.down('md')} {
        justify-content: center;
        align-items: center;
        padding-right: 0;
      }
    }

    .left-col {
      display: flex;
      justify-content: center;
      padding-right: ${theme.spacing(8)};

      &-inner {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
      }

      ${theme.breakpoints.down('md')} {
        display: flex;
        justify-content: center;
      }
    }

    .right-col {
      &-inner {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
      }

      ${theme.breakpoints.down('md')} {
        display: flex;
        justify-content: center;
        padding: ${theme.spacing(8, 2)};
      }

      button {
        margin-top: ${theme.spacing(4)};
        display: inline-block;
        align-self: flex-start;
      }
    }

    .consent-checkbox {
      .MuiFormControlLabel-label {
        font-size: ${theme.global.typography.size['size-12']};
      }
    }
  `
)
