import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '../Landing.styles'

export const AdvantagesStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(18)};
    padding-bottom: ${theme.spacing(0)};

    .advantage-item {
      ${theme.mixins.flexCenterV}

      img {
        width: ${theme.spacing(5)};
        margin-right: ${theme.spacing(2)};
      }
    }
    .image-container {
      overflow: hidden;
    }
  `
)
