import styled, { css } from 'styled-components'

export const ProjectTimelineStyled = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, ${theme.colors.grey200} 92.5%, ${theme.colors.white} 92.5%);
    padding-top: ${theme.spacing(10)};
    position: relative;

    ${theme.breakpoints.down('sm')} {
      background: #f4f4f4;
    }

    .content-headline {
      ${theme.breakpoints.down('sm')} {
        margin: ${theme.spacing(0, 0, 5)};
        text-align: left;
        width: 100%;
        padding: ${theme.spacing(0, 4)};
      }
    }

    .timeline-items {
      display: flex;
      width: 100%;
      max-width: 1260px;
      justify-content: space-between;
      padding: ${theme.spacing(0, 8)};

      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(0, 5, 5)};
        flex-direction: column;
        // align-items: flex-start;
        text-align: left;
        justify-content: flex-start;
        max-width: 100%;
      }

      .timeline-item {
        position: relative;

        ${theme.breakpoints.down('sm')} {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }

        .timeline-label,
        .timeline-date {
          position: absolute;
          left: 50%;
        }

        .timeline-label {
          transform: translate(-50%, -100%);
          top: ${theme.spacing(-1)};
          width: 150px;

          ${theme.breakpoints.down('md')} {
            width: auto;
          }
          ${theme.breakpoints.down('sm')} {
            transform: none;
            top: auto;
            position: relative;
            left: inherit;
            display: block;
            text-align: left;
            margin-left: 50px;
            font-size: 1rem;
          }
        }

        .timeline-date {
          transform: translate(-50%, 100%);
          bottom: ${theme.spacing(-1)};

          ${theme.breakpoints.down('sm')} {
            transform: none;
            top: auto;
            position: relative;
            left: inherit;
            display: block;
            text-align: left;
            margin-left: 50px;
            bottom: 0;
            font-size: 1rem;
          }
        }

        .timeline-marker {
          ${theme.mixins.flexCenter}
          ${theme.mixins.circle(theme.spacing(6))}

          background: ${theme.colors.grey200};
          border: ${theme.spacing(1.75)} solid ${theme.colors.white};

          img {
            padding: ${theme.spacing(0, 0.5)};
            background: ${theme.colors.white};
          }

          ${theme.breakpoints.down('sm')} {
            position: absolute;
            left: 0;
            top: 0;
            right: auto;
            bottom: auto;
            margin-left: -15px;
          }
        }
      }
    }
  `
)
