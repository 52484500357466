import { InitDetail } from 'lightgallery/lg-events'
import { LightGallery } from 'lightgallery/lightgallery'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlMouseEventHandler } from '@digital-magic/react-common'
import { PositiveInt } from '@api/endpoints/types'
import { Box, Grid } from '@mui/material'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { AmenitiesStyled } from './Amenities.styles'
import amenitiesImg1 from './images/1.jpg'
import amenitiesImg2 from './images/2.jpg'
import amenitiesImg3 from './images/3.jpg'
import amenitiesImg4 from './images/4.jpg'
import amenitiesImg5 from './images/5.jpg'
import amenitiesImg6 from './images/6.jpg'
import amenitiesImg7 from './images/7.jpg'
import amenitiesImg8 from './images/8.jpg'
import amenitiesImg9 from './images/9.jpg'
import amenitiesImg10 from './images/10.jpg'
import amenitiesImg11 from './images/11.jpg'
import amenitiesImg12 from './images/12.jpg'
import amenitiesImg13 from './images/13.jpg'
import amenitiesImg14 from './images/14.jpg'

type ImageInfo = Readonly<{
  url: string
  orderNumber: PositiveInt
}>

export const Amenities: React.FC = () => {
  const { t } = useTranslation()
  const lightGallery = React.useRef<LightGallery | null>(null)

  const [image1, image2, image3, image4, image5, ...otherImages] = React.useMemo(
    (): ReadonlyArray<ImageInfo> =>
      [
        {
          url: amenitiesImg1,
          orderNumber: 1
        },
        {
          url: amenitiesImg2,
          orderNumber: 2
        },
        {
          url: amenitiesImg3,
          orderNumber: 3
        },
        {
          url: amenitiesImg4,
          orderNumber: 4
        },
        {
          url: amenitiesImg5,
          orderNumber: 5
        },
        {
          url: amenitiesImg6,
          orderNumber: 6
        },
        {
          url: amenitiesImg7,
          orderNumber: 7
        },
        {
          url: amenitiesImg8,
          orderNumber: 8
        },
        {
          url: amenitiesImg9,
          orderNumber: 9
        },
        {
          url: amenitiesImg10,
          orderNumber: 10
        },
        {
          url: amenitiesImg11,
          orderNumber: 11
        },
        {
          url: amenitiesImg12,
          orderNumber: 12
        },
        {
          url: amenitiesImg13,
          orderNumber: 13
        },
        {
          url: amenitiesImg14,
          orderNumber: 14
        }
      ].sort((a, b) => a.orderNumber - b.orderNumber),
    []
  )

  const onViewGalleryClick: HtmlMouseEventHandler = (e) => {
    e.preventDefault()
    lightGallery.current?.openGallery()
  }

  return (
    <AmenitiesStyled id="gallery">
      <Text component="div" size={{ xs: 'size-34', sm: 'size-46' }} lineHeight="1.25em" mb={4} ml={-0.3}>
        {t('pages.landing.about.amenities.title')}
      </Text>
      <Text
        component="div"
        size={{ xs: 'size-18', sm: 'size-24' }}
        mb={4}
        textAlign="justify"
        className="text-subtitle"
      >
        {t('pages.landing.about.amenities.subtitle')}
      </Text>
      <Text
        component="div"
        size={{ xs: 'size-14', sm: 'size-18' }}
        weight="regular"
        mb={6}
        textAlign="justify"
        className="text-description"
      >
        {t('pages.landing.about.amenities.description')}
      </Text>
      <div className="image-container">
        <ImageGallery
          onInit={(detail: InitDetail) => {
            // eslint-disable-next-line functional/immutable-data
            lightGallery.current = detail.instance
          }}
          selector=".landing-amenities-images"
        >
          <Grid container spacing={2}>
            {[image1, image2, image3, image4, image5].map((image, index) => (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <ImageItem src={image.url} className="landing-amenities-images" />
              </Grid>
            ))}
            {otherImages.map((img, index) => (
              <Box key={index} display="none">
                <ImageItem src={img.url} className="landing-amenities-images" />
              </Box>
            ))}
            <Grid item xs={12} md={6} lg={4} className="action-panel">
              <Link onClick={onViewGalleryClick} className="view">
                <div className="box">{t('pages.landing.about.amenities.buttons.view-gallery')}</div>
              </Link>
            </Grid>
          </Grid>
        </ImageGallery>
      </div>
    </AmenitiesStyled>
  )
}
