import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { Link } from '@controls/Link'
import { hasValue } from '@digital-magic/ts-common-utils'
import { toRequiredFieldLabel } from '@utils/formatting-utils'
import { SendMessageRequest, useSendMessage } from '@api/endpoints/messages'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { useGetLanguage } from '@hooks/useGetLanguage'
import { Box, FormHelperText, Grid } from '@mui/material'
import { Button } from '@controls/Button'
import { ContactAvatar } from '@controls/ContactAvatar'
import { Form, FormCheckbox, FormTextField, useFormTyped } from '@controls/Form'
import { Text } from '@controls/Text'
import { Developer } from '../Contact/Developer'
import { ContactStyled } from './Contact.styles'

const FeedbackForm = SendMessageRequest.extend({
  consent: z.literal(true)
})
type FeedbackForm = z.infer<typeof FeedbackForm>

export const Contact: React.FC = () => {
  const { t } = useTranslation()
  const language = useGetLanguage()

  const defaultErrorHandler = useDefaultErrorHandler()

  const onSendMessageSuccess: React.DispatchWithoutAction = () => {
    form.reset()
  }

  const sendMessage = useSendMessage({
    onError: defaultErrorHandler,
    onSuccess: onSendMessageSuccess
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = ({ consent, ...request }: FeedbackForm): void => {
    sendMessage.mutate(request)
  }

  const form = useFormTyped({
    resolver: FeedbackForm,
    mode: 'onSubmit',
    onSubmit,
    defaultValues: {
      language
    }
  })

  React.useEffect(() => {
    form.setValue('language', language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <ContactStyled id="landing_contact_us">
      <Grid container>
        <Grid item xs={12} md={6} className="developer-column">
          <Developer />
          <ContactAvatar />
        </Grid>
        <Grid item xs={12} md={6} className="right-col">
          <Form f={form} className="right-col-inner">
            <Text component="div" size={{ xs: 'size-24', sm: 'size-34' }} mb={4} ml={-0.3}>
              {t('pages.landing.contact.title')}
            </Text>
            <Box display="flex" flexDirection="column" rowGap={2} mb={3}>
              <FormTextField
                name={form.names.displayName}
                label={toRequiredFieldLabel(t('pages.landing.contact.name'))}
              />
              <FormTextField
                name={form.names.emailAddress}
                label={toRequiredFieldLabel(t('pages.landing.contact.email'))}
              />
              <FormTextField
                name={form.names.phoneNumber}
                label={toRequiredFieldLabel(t('pages.landing.contact.phone'))}
              />
              <FormTextField
                name={form.names.message}
                label={toRequiredFieldLabel(t('pages.landing.contact.message'))}
                multiline
              />
              <Box display="flex">
                <Box mr={1} alignSelf="center">
                  <FormCheckbox className="consent-checkbox" name={form.names.consent} hideErrorMessage={true} />
                </Box>
                <Box>
                  <Text size={'size-16'}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                    {t('pages.landing.contact.consent', { button: t('pages.landing.contact.submit') })}{' '}
                    <Link href={`/docs/terms_data_processing_${language.toLowerCase()}.pdf`} target="_blank">
                      {t('pages.landing.contact.terms')}
                    </Link>
                  </Text>
                </Box>
              </Box>
              {hasValue(form.formState.errors.consent) && (
                <FormHelperText error={true}>{t('pages.booking.form.errors.terms_consent')}</FormHelperText>
              )}
            </Box>
            <Button
              id="btnContactFormSubmit"
              color="grey800"
              loading={sendMessage.isLoading}
              onClick={form.handleSubmit(form.onSubmit)}
            >
              {t('pages.landing.contact.submit')}
            </Button>
          </Form>
        </Grid>
      </Grid>
    </ContactStyled>
  )
}
