import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '../Landing.styles'

export const HousesStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    padding: ${theme.spacing(18, 0)};

    .house-image {
      width: 100%;
      height: 400px;
      background-size: cover;
      background-position: center center;
      background-color: ${theme.colors.grey300};

      .virtual-tour-link {
        display: flex;
        margin-top: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 15px 20px;
        text-align: center;
        text-decoration: none;
        font-size: ${theme.global.typography.size['size-14']};
        color: ${theme.colors.white};
        background-color: ${theme.colors.brown};
        max-width: 100%;
        border: 0;
      }
    }
  `
)
