import styled, { css } from 'styled-components'
import { Box, BoxProps } from '@mui/material'

export const LandingStyled = styled('div')(() => css``)

export const SectionContainerStyled = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>
    <div className="section-inner">{children}</div>
  </Box>
))(
  ({ theme }) => css`
    .section-inner {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      padding: ${theme.spacing(0, 2)};
    }
  `
)
