import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { DeveloperStyled } from './Developer.style'

export const Developer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <DeveloperStyled>
      <Text weight="bold" size={{ xs: 'size-18', sm: 'size-24' }} mb={4} component="div" ml={-0.24}>
        {t('app.main.contacts.project_developer.created_by')}
      </Text>
      <Text size={{ xs: 'size-14', sm: 'size-18' }} weight="regular">
        {t('app.main.contacts.project_developer.architecture') + ': '}
        <Link href="https://abpanda.ee" target="_blank" rel="noopener" className="text-link">
          {t('app.main.contacts.project_developer.architecture_link')}
        </Link>
        <br />
        {t('app.main.contacts.project_developer.main_contractor') + ': '}
        <Link href="https://unico.ee" target="_blank" rel="noopener">
          {t('app.main.contacts.project_developer.main_contractor_link')}
        </Link>
        <br />
        {t('app.main.contacts.project_developer.interior_designer') + ': '}
        {t('app.main.contacts.project_developer.interior_designer_link')}
      </Text>
    </DeveloperStyled>
  )
}
