import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { CompanyInfoStyled } from './CompanyInfo.style'
import unicoLogo from './unico-dark.svg'

export const CompanyInfo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <CompanyInfoStyled>
      <div className="text-container">
        <Text size={{ xs: 'size-34', sm: 'size-46' }} mb={3} component="div" className="text-block" ml={-0.3}>
          {t('pages.landing.company_info.title')}
        </Text>
        <Text
          size={{ xs: 'size-18', sm: 'size-24' }}
          className="text-description"
          textAlign="justify"
          whiteSpace="pre-wrap"
        >
          <Link href="https://unico.ee/" target="_blank" rel="noopener">
            {t('pages.landing.company_info.company_link')}
          </Link>
          {t('pages.landing.company_info.description')}
        </Text>
        <Text
          size={{ xs: 'size-16', sm: 'size-18' }}
          className="text-description"
          textAlign="justify"
          whiteSpace="pre-wrap"
        >
          {t('pages.landing.company_info.description2')}
        </Text>
        <Text
          size={{ xs: 'size-16', sm: 'size-18' }}
          className="text-description"
          textAlign="justify"
          whiteSpace="pre-wrap"
        >
          <Link href="https://unico.ee/portfoolio/kinnisvaraarendus/" target="_blank" rel="noopener">
            {t('pages.landing.company_info.unico_projects')}
          </Link>
        </Text>
      </div>
      <div className="image-container">
        <img src={unicoLogo} alt="unico-logo" className="unico-logo" loading="lazy" />
      </div>
    </CompanyInfoStyled>
  )
}
