import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '@pages/Customer/Landing/Landing.styles'

export const CompanyInfoStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    padding: ${theme.spacing(18, 0, 18)};
    background: ${theme.colors.grey200};

    .section-inner {
      display: flex;
      row-gap: ${theme.spacing(4)};
      column-gap: ${theme.spacing(4)};
      flex-direction: row;

      ${theme.breakpoints.down('md')} {
        flex-direction: column;
      }
    }

    .unico-logo {
      width: 100%;
      max-width: 200px;
    }
    .text-container {
      padding-right: 40px;
      max-width: 1200px;
    }
    .title {
      line-height: 50px;
    }
    .text-description {
      max-width: 850px;
      display: block;
      padding: ${theme.spacing(2, 0)};
    }
    .image-container {
      position: relative;
    }
  `
)
