import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { hasValue } from '@digital-magic/ts-common-utils'
import { LayoutTypePublicView, useGetLayoutTypesPublic } from '@api/endpoints/buildings/layoutTypes'
import { fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { Box, Grid } from '@mui/material'
import { Button } from '@controls/Button'
import { Text } from '@controls/Text'
import { HousesStyled } from './Houses.styles'
import virtualTourImage from '@icons/virtual_tour.png'

export const Houses: React.FC = () => {
  const { t } = useTranslation()
  const defaultErrorHandler = useDefaultErrorHandler()
  const translateValue = useTranslatedValue()

  const getHouseItems = useGetLayoutTypesPublic({ onError: defaultErrorHandler })

  const houseItems = React.useMemo(
    () => getHouseItems.data?.concat()?.sort((a, b) => a.orderNumber - b.orderNumber) ?? [],
    [getHouseItems.data]
  )

  const onVirtualTourClick: (item: LayoutTypePublicView) => HtmlMouseButtonEventHandler = (item) => (e) => {
    e.preventDefault()
    if (item.virtualTourUrl) {
      window.open(item.virtualTourUrl, '_blank') //.focus()
    }
  }

  return (
    <HousesStyled>
      <Text size={{ xs: 'size-34', sm: 'size-46' }} mb={3} component="div" ml={-0.3}>
        {t('pages.landing.houses.title')}
      </Text>
      <Text size={{ xs: 'size-18', sm: 'size-24' }} textAlign="justify" mb={8} component="div">
        {t('pages.landing.houses.description')}
      </Text>
      <Grid container columnSpacing={12} rowSpacing={8}>
        {houseItems.map((item, i) => (
          <Grid key={i} item display="flex" flexDirection="column" xs={12} md={6}>
            {hasValue(item.image) && (
              <Box
                className="house-image"
                style={{ backgroundImage: `url(${fileUrl(getLargeThumbnailId(item.image))})` }}
                position="relative"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                {hasValue(item.virtualTourUrl) && (
                  <Button onClick={onVirtualTourClick(item)} className="virtual-tour-link">
                    <img src={virtualTourImage} width="35px" height="21px" alt="virtual tour" loading="lazy" />
                    <Box ml={1}>{t('pages.landing.houses.virtual_tour')}</Box>
                  </Button>
                )}
              </Box>
            )}
            <Text color="brown" size={{ xs: 'size-18', sm: 'size-24' }} weight="regular" mt={2} mb={1}>
              {translateValue(item.title)}
            </Text>
            <Text size={{ xs: 'size-16', sm: 'size-18' }} weight="regular" textAlign="justify">
              {translateValue(item.description)}
            </Text>
          </Grid>
        ))}
      </Grid>
    </HousesStyled>
  )
}
