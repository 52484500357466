import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'

export const PolygonLinkStyled = styled(Link)(
  ({ theme }) => css`
    ${theme.mixins.engulfAbsolute}

    display: flex;
    transition: all 0.2s;

    &.disabled {
      background: ${theme.mixins.opacity(theme.colors.green, 0.5)};
    }

    &:not(.disabled):hover {
      background: ${theme.mixins.opacity(theme.colors.brown, 0.75)};
    }
  `
)

export const PolygonTooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(
  ({ theme }) => css`
    & .${tooltipClasses.tooltip} {
      padding: ${theme.spacing(2)};
      border-radius: 0;
      background: ${theme.mixins.opacity(theme.colors.black, 0.75)};
    }

    & .${tooltipClasses.arrow} {
      color: ${theme.mixins.opacity(theme.colors.black, 0.75)};
    }
  `
)
