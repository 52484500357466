import styled, { css } from 'styled-components'

export const DeveloperStyled = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    //align-items: center;
    margin-bottom: ${theme.spacing(10)};
  `
)
