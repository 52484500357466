import { BuildingAddress } from '@api/endpoints/buildings/houses'

type BuildingMap = Record<BuildingAddress, ReadonlyArray<string>>

export const buildingPathMap: BuildingMap = {
  'Portsmarja tee 6': ['642.6,60.4 658.5,64.4 657.2,65.7 712.1,75.7 735.2,47.4 668,37.5'],
  'Portsmarja tee 4': ['712.1,75.7 688.9,101.3 634.8,90.2 657.2,65.7'],
  'Portsmarja tee 2': ['660.8,132.2 671.6,114.8 688.9,101.3 634.8,90.2 608.9,114.5 636.2,125'],
  'Põldmarja tee 27': [
    '876.4,329.5 873.8,354.9 875.8,368.8 952.8,375.2 954,352.3 958.4,345.7 958.4,335.6',
    '952.8,375.2 954,352.3 958.4,345.7 958.4,335.6 982.1,337.9 981.2,344.6 982.1,355.8 980.4,369.6 980.6,377.5',
    '982.1,337.9 981.2,344.6 982.1,355.8 980.4,369.6 980.6,377.5 1005.2,379.8 1005.2,364.5 1006.3,356.6 1010,350.3 1010,339.8',
    '1005.2,379.8 1032.5,382 1032.5,374.5 1034.3,358.8 1032.9,349.8 1033.4,340.9 1010,339.8 1010,350.3 1006.3,356.6 1005.2,364.5',
    '1033.4,340.9 1032.9,349.8 1034.3,360.9 1032.5,374.5 1032.5,382 1059.3,383.4 1060.3,394.8 1122.7,400.3 1120.6,349.9 1075.2,343 1061.1,342'
  ],
  'Põldmarja tee 23': [
    '943.3,258.6 967.8,260.7 971.8,267.1 969.6,268.9 966.5,303.2 939.5,299.7 891,295.9 916.1,261.8 942.4,263.8',
    '966.5,303.2 969.6,268.9 971.8,267.1 967.8,260.7 968,259.2 993.4,261 991.8,271.3 989.7,304.8',
    '989.7,304.8 991.8,271.3 993.2,262.7 1016.8,264.5 1021.2,270.8 1018.6,272.4 1015.4,306.8',
    '1015.4,306.8 1018.6,272.4 1021.2,270.8 1015.4,262.2 1042.9,265 1039.3,309.1',
    '1039.3,309.1 1042.9,265 1064.7,265.7 1064.9,264.4 1118.4,268.3 1119.9,316.7'
  ],
  'Põldmarja tee 21': [
    '965.8,221.6 980.3,222.7 980.5,230.1 977.4,257.4 922.2,253 927.6,245.6 931.4,235.4 933,224 964.7,226.4',
    '977.4,257.4 980.5,230.1 980.5,221.4 995.2,222.6 994.7,231.7 992.7,244.3 991.6,258.2',
    '991.6,258.2 992.7,244.3 994.7,231.7 995.2,224 1008.9,225 1008.9,234.3 1005.4,259.5',
    '1005.4,259.5 1007.9,241.7 1008.9,234.3 1008.9,223.6 1023.4,225 1023.4,234.1 1021.2,248.2 1019.9,260.8',
    '1019.9,260.8 1021.2,246.9 1023.4,234.1 1023.4,209 1033.3,207.7 1117.9,261.7 1118.4,268.3 1035.8,261.8'
  ],
  'Põldmuraka tee 3': [
    '781,99.8 770.1,93.2 770.7,88.6 778,86 778,79.3 787.6,76 789.4,76.7 816.8,69.3 850.2,90.7 834.8,108.8 816.2,105.4 810.2,111.2 792,106.4',
    '816.8,69.3 789.4,76.7 787.6,76 778,79.3 778,86 770.7,88.6 770.1,93.2 765.3,88 765.3,83.2 768.8,81.7 769,75.5 807.6,63.6',
    '765.3,88 760.5,82.8 760.5,77.9 762.9,76.6 762.9,70.4 775.4,66 776.5,66.8 800.4,59 807.6,63.6 769,75.5 768.8,81.7 765.3,83.2',
    '760.5,82.8 754.3,84.5 749.8,79.1 749.8,74.3 753.2,72.7 753.4,66.5 792.9,55.1 800.4,59 776.5,66.8 775.4,66 762.9,70.4 762.9,76.6 760.5,77.9',
    '749.8,79.1 745,73.9 728.4,77.3 715.2,72.9 735.2,47.4 777,53.3 792.9,55.1 753.4,66.5 753.2,72.7 749.8,74.3'
  ],
  'Põldmarja tee 13': [
    '709.1,117.3 701.4,112.6 685.5,120.4 676.3,135.8 660.8,132.2 671.6,114.8 691.2,99.5 705.5,82.6 729.2,93.2 712,104 711.8,110.4 709.1,112.2',
    '721.5,120.4 716.8,122.5 709.1,117.3 709.1,112.2 711.8,110.4 712,104 729.2,93.2 739.9,97.3 723.9,106.7 723.6,113.6 721.5,115',
    '729.2,125 721.5,120.4 721.5,115 723.6,113.6 723.9,106.7 739.9,97.3 748.9,101.3 732.4,111.5 732,118.2 729.2,120',
    '737.2,130.3 729.2,125 729.2,120 732,118.2 732.4,111.5 748.9,101.3 758,105.4 744.3,114.4 744.3,121.3 737.2,125.3',
    '751.3,135.4 747.8,133.9 737.2,130.3 737.2,125.3 744.3,121.3 744.3,114.4 758,105.4 769.7,110.9 784.4,115.5 790,114 804.2,117.5 818.4,130.3 810.2,147.7 782.4,146.8 758,147.3 738.1,145'
  ],
  'Tuvimarja tee 4': [
    '492.3,67.1 509.2,69.4 557.4,76 564.3,69 514.8,60 513.9,48.1 502.3,47.2 495.8,52.8 494.2,56.6 492.8,61.6',
    '513.9,48.1 514.8,60 564.3,69 570.9,61.2 521.4,51.5 520.4,42 505.9,40.2 499.9,46.8',
    '509.6,38.2 516.2,30.6 527.9,31.2 528.9,43.2 578.2,52.6 570.9,61.2 521.4,51.5 520.4,42 505.9,40.2 507.5,38.3',
    '514.4,30.5 521.6,21.7 534.8,23.4 536.1,34.5 586.9,42.6 578.2,52.6 528.9,43.2 527.9,31.2',
    '586.9,42.6 536.1,34.5 534.8,23.4 523.5,22 530.5,13.9 543.5,15.8 543.9,27.1 593.9,34.5'
  ],
  'Põldmarja tee 8': [
    '618.1,179.2 624.7,158.9 624.8,147.7 630.8,140.5 642.9,142.7 642.9,148 643.7,148 643.7,153.4 656.5,155 662,148.3 688.8,153.2 665.3,186.6',
    '604.2,177.1 614.1,155.2 614,144.2 618.8,137.5 631.4,139.7 624.8,147.7 624.7,158.9 618.1,179.2',
    '590.2,174.8 600.4,154.9 600.5,142.9 605.9,136.5 618.1,138.9 614,144.2 614.1,155.2 604.2,177.1',
    '577.6,172.5 589.3,151.3 589.3,140 593.9,133.1 606.6,135.6 600.5,142.9 600.4,154.9 590.2,174.8',
    '588,123.1 574.2,118.7 561.2,115.9 527.7,164.7 577.6,172.5 589.3,151.3 589.3,140 598,126.9'
  ],
  'Metsise tee 33': [
    'M552,205.6l-12,10.5c0,0-10.9-2.6-21.5-4.8c-10.6-2.2-20-4-20-4l52.1-38.4l29,3.9l-25,18.9l0.3,6.6l-2.8,2.6V205.6z',
    '561.4,211.5 552,205.6 552,200.9 554.8,198.3 554.5,191.6 579.6,172.7 593.9,175.5 567.7,194.1 567.7,201.3 563.4,204.3 563.6,210',
    '574.2,213.7 563.6,210 563.4,204.3 567.7,201.3 567.7,194.1 593.9,175.5 607.8,177.7 576.8,199.2 576.8,206.2 574.2,208.4',
    '583.3,218.9 574.2,213.7 574.2,208.4 576.8,206.2 576.8,199.2 607.8,177.7 623.5,180 589.6,202.4 590.2,209.4 583.3,213.7',
    'M622,254.2c0,0-11.5-6.8-25.6-14.6s-26.4-14.2-26.4-14.2l13.2-6.6v-5.2l6.9-4.3l-0.6-7l33.9-22.3l63.1,9.6L622,254.2z'
  ],
  'Metsise tee 22': [
    '568.2,258.5 525.7,249 525.5,244.4 488.3,238 488.2,243.5 475.5,241.1 482.4,232.6 487,233.5 494.8,223.2 503,224.3 543.3,233.7 575.6,249',
    '557.7,271.9 516.1,262 515.8,257.2 478.2,250.7 478.8,256.7 465.3,253.7 472.7,245.2 476.8,245.2 479.8,241.8 488.2,243.5 488.3,238 525.5,244.4 525.7,249 568.2,258.5',
    '547.6,284.8 506.1,274.9 505.5,270.2 468.1,263.8 468.3,269.7 455.3,266.5 462.1,257.7 466.9,258.6 470.2,255 478.8,256.7 478.2,250.7 515.8,257.2 516.1,262 557.7,271.9',
    '537.9,297.3 495.9,288.1 495.3,283.4 457.4,277.2 457.6,282.7 444.8,279.8 452.3,270.3 456.8,271.6 460.4,267.5 468.3,269.7 468.1,263.8 505.5,270.2 506.1,274.9 547.6,284.8',
    '432.9,295.2 525,313.7 537.9,297.3 495.9,288.1 495.3,283.4 457.4,277.2 457.6,282.7 448.9,280.5 446.2,284.9 441,283.8'
  ],
  'Metsise tee 26': [
    '555.6,359.4 556.3,331 556.3,321.8 571.4,321.8 571.8,326.7 600.5,327.2 600.5,325 627.2,325.2 628,337.3 620.6,358.4',
    '540.5,359.4 540.5,319.8 556.3,319.8 556.3,331 555.6,359.4',
    '525,359.5 525.8,331.3 525.8,321.8 540.5,321.8 540.5,332.8 540.5,359.4',
    '509.8,359.4 510.6,344.3 510.6,320.3 525.8,320.3 525.8,331.3 525,359.5',
    '509.8,359.4 510.6,344.3 510.6,329.8 510.6,321.8 494.7,321.8 493.3,313.7 449.4,312.4 410.8,351.7 409.7,360.2'
  ],
  'Metsise tee 28': [
    '525.8,429.1 524,398.4 524,372.9 539.7,373.6 540.5,379.7 579.1,380.3 596.7,387 555.6,421',
    '509.1,432.5 507.3,385.3 508.2,371.9 524,371.3 524,383.4 524,398.4 525.8,429.1 511.8,432.5',
    '491.9,431 491.5,383.6 492.5,373.6 508,373.6 507.3,385.3 509.1,432.5',
    '475.1,429.1 476.2,396.5 476.2,371.9 492.5,371.9 491.5,383.6 491.9,431',
    '457.4,429.1 476.2,429.1 477.3,396.5 477.3,382.2 477.3,360.2 410.8,360.2 402.1,434.1 433.7,429.8'
  ],
  'Tuvimarja tee 2': ['486.7,65.7 486.1,73.3 479.8,82.7 477.4,95.4 498.6,96.6 539.2,100 558.7,76 509.2,69.4'],
  'Põldmarja tee 5': [
    'M459.8,96.2l3.1-17.7l-5.2-3l3.8-12.6l-57.9-1.6l17,40c0,0,6.3-1.3,20.3-2.9S459.8,96.2,459.8,96.2z'
  ],
  'Põldmarja tee 2': ['M349.1,137l83.1,13l-6.3-36.1c0,0-22,2.8-41.8,8.4s-29.3,9.7-29.3,9.7L349.1,137z'],
  'Põldmarja tee 4': ['M432.2,149.9l45.1,6.9l21.2-47.6c0,0-12.5-1-31-0.4c-18.5,0.6-41.7,5.1-41.7,5.1L432.2,149.9z'],
  'Põldmarja tee 6': ['M477.4,156.8l50.3,7.9l33.5-48.9c0,0-13.2-3.9-36.5-5.5c-23.3-1.6-26.1-1.2-26.1-1.2L477.4,156.8z'],
  'Põldmarja tee 9': [
    'M595.8,109.7l32.7-33l-10.4-7.4l-46.2-9.5L539.2,100c0,0,19.2,2.7,30.8,4.4C581.5,106.1,595.8,109.7,595.8,109.7z'
  ],
  'Portsmarja tee 3': ['571.9,59.8 619.8,69.4 633,58.5 642.6,60.4 668,37.5 599.2,28.5'],
  'Põldmuraka tee 2': [
    '869.9,164.5 829.9,151.9 836.1,142.9 848.1,144.7 851.3,132.6 866.6,134.6 867.2,138.1 882.8,140.2 882.8,141.9 910.4,146.2',
    '851.3,132.6 853.8,130.9 844.4,129.3 853,110.5 863.6,99.1 925.9,139.2 910.4,146.2 882.8,141.9 882.8,140.2 867.2,138.1 866.6,134.6'
  ],
  'Põldmarja tee 17': ['972.8,169.8 907.7,187.3 887.7,173.3 869.9,164.5 925.9,139.2'],
  'Põldmarja tee 19': [
    'M930,215.1l103.3-7.4l-60.6-37.9l-65.1,17.5c0,0,9.9,7.9,15.2,14.9C928.3,209.2,930,215.1,930,215.1z'
  ],
  'Metsise tee 24': ['575.6,249 599.8,265.6 618.3,286.1 625.7,302.5 627.2,313.7 525,313.7'],
  'Põldmarja tee 10': ['688.8,153.2 716.7,157 742.2,159.5 719.4,194.3 665.3,186.6'],
  'Põldmarja tee 12': [
    'M742.2,159.5c0,0,13.4,0,31.8,0.3c22.1,0.4,44.9,5.3,44.9,5.3l-42.8,39.9l-12.3-3.9l-44.4-6.7L742.2,159.5z'
  ],
  'Põldmarja tee 14': [
    'M818.9,165.1c0,0,12.3,2.5,29.7,8.8c15,5.4,31.1,16.6,31.1,16.6l-72.5,24.3l-31.1-9.8L818.9,165.1z'
  ],
  'Põldmarja tee 16': [
    'M879.7,190.5l2.6,1.9l17.2,22.1l3.9,13c0,0-0.6,7.6-1.7,10.2c-1.1,2.6-3.7,5.2-3.7,5.2l-89.2-8.2l-1.7-19.8L879.7,190.5z'
  ],
  'Mõtuse tee 3': ['763.8,201 740.3,221.1 738.6,223.7 730.7,220.6 719.4,231.4 664,213.7 686.6,189.7 719.4,194.3'],
  'Mõtuse tee 1': [
    'M664,213.7l35.9,11.4l-14.3,13.7l13.1,14.1c0,0-6.5,7.3-18.6,10.9c-12.1,3.5-28.7,6.7-28.7,6.7l-4.1-0.3l-25.3-16L664,213.7z'
  ],
  'Mõtuse tee 6': ['807.3,214.8 776.1,204.9 763.8,201 740.3,221.1 732.7,232.8 730,237.7 793.3,257.1 808.9,234.6'],
  'Põldmarja tee 18': ['898.1,242.8 895.7,247.8 869,279.6 784.3,270.2 808.9,234.6'],
  'Mõtuse tee 4': ['697.7,270.5 708.1,265.2 716.6,258.8 730,237.7 793.3,257.1 784.3,270.2 752.8,309.1'],
  'Mõtuse tee 2': [
    'M697.7,270.5c0,0-17.9,6.2-27.9,7.9c-10,1.7-15.6,2.1-15.6,2.1l2.5,6.2l-1.4,11l2.3,7.5l2.5,14.8l92.7,2.6v-13.5L697.7,270.5z'
  ],
  'Põldmarja tee 20': ['M848.1,316.8c0,0,8.6-16.9,13.6-25.8c5-8.9,7.2-11.3,7.2-11.3l-84.7-9.4l-31.5,38.9L848.1,316.8z'],
  'Metsise tee 39': ['752.8,322.6 753.7,360.1 657.6,350.4 660.3,340.2 660.1,320'],
  'Põldmarja tee 22': ['M752.8,309.1l95.3,7.6c0,0-5.3,12.1-6.1,23.3c-0.8,11.2-0.8,17-0.8,17l-87.6-5.6L752.8,309.1z'],
  'Metsise tee 41': ['753.7,360.1 746.8,399.1 641.6,382.1 650.7,369.3 655.7,358.5 657.6,350.4'],
  'Põldmarja tee 24': ['M841.1,357c0,0,1.2,13.6,5.4,26.2s5.3,17.5,5.3,17.5l-105.1-1.6l6.9-39l-0.2-8.7L841.1,357z'],
  'Põldmarja tee 29': ['987.3,429.9 985.9,388.9 879.2,381.2 887,400.7 905.7,425.8'],
  'Karusmarja tee 3': ['1123.5,438.3 1080.1,433.5 1021.2,441.3 987.3,429.9 987.3,388.4 1122.7,400.3'],
  'Metsise tee 43': ['746.8,399.1 734.4,436.7 613.4,412.8 625,399.1 628.3,399.1 635.4,389.2 641.6,382.1'],
  'Põldmarja tee 26': ['746.8,399.1 851.8,400.7 864.9,426.7 868.3,441.3 734.4,436.7'],
  'Metsise tee 45': ['734.4,436.7 722.1,463.8 594.8,457.7 598.2,433.1 613.4,412.8'],
  'Põldmarja tee 28': [
    'M734.4,436.7l-12.4,27.1l44.6,6.1l75.8,31.8c0,0,19.7-17,24.3-31.1c4.6-14.1,1.5-29.2,1.5-29.2L734.4,436.7z'
  ],
  'Põldmarja tee 32': ['722.1,463.8 716.1,528.8 693.1,524.7 672.8,517.3 658.7,510.3 645.4,500.8 594.8,457.7'],
  'Põldmarja tee 30': [
    '722.1,463.8 716.1,528.8 742.1,531.2 761.8,531.2 780.5,528 798.1,523.9 816.7,517.3 834,508.5 842.5,501.7 766.7,469.9'
  ],
  'Karusmarja tee 5': ['1125.6,492.5 1035.6,480.4 1021.2,441.3 1080.1,433.5 1123.5,438.3'],
  'Karusmarja tee 2': ['901,477.4 909.3,441.3 978.5,444.9 993.4,483.5 1003.2,516.1'],
  'Karusmarja tee 7': ['993.4,483.5 1035.6,480.4 1125.6,492.5 1127,555.3 1003.2,518'],
  'Sinimarja tee 1': ['901,477.4 1003.2,516.1 1003.2,518 936.6,562.2 877.9,521.6'],
  'Sinimarja tee 3': [
    '944.3,557.1 948.2,560.5 958.7,552.9 990.2,573.4 995.7,569.3 996.4,563.2 1043.1,530 1003.2,518',
    '990.2,573.4 1015.2,590.1 1021.4,586 1022.4,578.8 1077.4,540.3 1043.1,530 996.4,563.2 995.7,569.3',
    '1015.2,590.1 1041.7,605.8 1047.2,602.3 1048.3,595.4 1110.7,550.4 1077.4,540.3 1022.4,578.8 1021.4,586',
    '1041.7,605.8 1068.6,623 1073.4,619.4 1074.8,612 1127,574.1 1127,555.3 1110.7,550.4 1048.3,595.4 1047.2,602.3',
    '1068.6,623 1096.5,639.2 1082.5,648.3 1131.3,669.9 1127,574.1 1074.8,612 1073.4,619.4'
  ],
  'Sinimarja tee 4': [
    'M969.1,615.3l-5.6,7.8c0,0-14.9,8.3-17.8,24.5c-2.9,16.2-5.5,64.7-5.5,64.7l98.8,3.5l52.4-25.6l-48.5-31.4L969.1,615.3z'
  ],
  'Sinimarja tee 2': [
    '835.5,600.7 861.2,602.3 861.2,595.4 905.6,598 905.6,600.7 945.7,600.7 877.9,555.3 871.8,558.4 870.2,557.4 855.6,565.7 855.3,584.7 836.2,584.4',
    '945.7,600.7 905.6,600.7 905.6,598 861.2,595.4 861.2,602.3 842.3,601.1 842.3,607.2 835.5,607.2 835.5,622.5 861.6,624.7 861.6,617.8 906.1,619.8 906.1,622.3 963.5,623.1 969.1,615.3',
    'M963.5,623.1l-57.4-0.8v-2.5l-44.5-2v6.9l-19-1.6v5.9h-7.1v17l25.3,1.5l0.4-6.7L907,643l-0.2,3l38.9,1.5c0,0,2.3-7.3,6.7-14C956.8,626.8,963.5,623.1,963.5,623.1z',
    'M945.7,647.5l-38.9-1.5l0.2-3l-45.8-2.2l-0.4,6.7l-18.9-1.1v5.8h-6.4v17.8l25.7,1.5v-7.1l45.9,2.5v2.2l34.5,1.5c0,0-0.4-6.5,0.7-13.3C943.5,650.6,945.7,647.5,945.7,647.5z',
    '941.7,670.6 907.2,669.2 907.2,667 861.2,664.4 861.2,671.5 841.6,670.4 841.6,676.1 835.5,676.1 834.5,707.8 940.2,712.2'
  ],
  'Põldmarja tee 37': [
    'M725.3,591.4l24.7,1.5l0.9-6.8l44,2.1v3l34.9,1.7l0.6-49.2c0,0-40.8,7.8-52.5,9.6c-11.7,1.7-32.8,1.1-32.8,1.1l-1.9,21.5l-16.6-0.1L725.3,591.4z',
    '829.7,592.8 794.8,591.1 794.8,588.1 750.9,586 750,592.8 731.6,591.7 731.6,597.6 725.3,597.6 724.2,614.1 748.3,615.3 749,608.2 793.1,610.3 793.5,613.7 829.7,615.3',
    '829.7,615.3 793.5,613.7 793.1,610.3 749,608.2 748.3,615.3 730,614.4 730,619.8 723.5,619.8 722.1,636.3 746.8,637.9 747,630.9 792.4,633.6 792.4,636.3 828.7,637.9',
    '828.7,637.9 792.4,636.3 792.4,633.6 747,630.9 746.8,637.9 728.1,636.7 728.1,642.4 721.3,642.4 720.3,659.4 745,661.2 745,654.4 791.1,656.5 791.1,659.4 828.7,661.2',
    '828.7,661.2 791.1,659.4 791.1,656.5 745,654.4 745,661.2 725.9,659.8 725.9,665.6 719.4,665.6 717.5,697 826.7,707'
  ],
  'Põldmarja tee 39': [
    'M641.6,579.2l43.7,2.8v3l34,2.5l2.7-33.8c0,0-16.2-2.2-35.2-7.4c-19.1-5.3-33.4-11.1-33.4-11.1L638.4,549l-3,21.4l-16.2-1.2l-2.1,15.2l24,1.4L641.6,579.2z',
    '638.7,601.3 682.5,604.1 682.5,606.2 717.5,608.2 719.4,587.4 685.4,585 685.4,582 641.6,579.2 641.1,585.8 623,584.5 622.4,590.8 616,590.7 613.9,606 638,607.2',
    '638.7,601.3 638,607.2 619.8,606.2 619.3,612.8 612.7,612.6 610.2,628.3 634.3,630.1 635.2,624.1 679.9,626.5 680.7,628.7 715.1,631.6 717.5,608.2 682.5,606.2 682.5,604.1',
    '635.2,624.1 634.3,630.1 616.6,629 615.9,635.2 609,634.8 606.7,651.5 630.9,652.9 631.9,647.4 677.2,649.8 677.2,652.6 712.8,655.4 715.1,631.6 680.7,628.7 679.9,626.5',
    '631.9,647.4 630.9,652.9 613,651.9 612.3,658.6 604.8,658.1 599.3,692.7 709.4,699.3 712.8,655.4 677.2,652.6 677.2,649.8'
  ],
  'Põldmarja tee 41': ['621.4,516.2 567.9,482.3 529.7,477.2 526.5,547.1 615.2,553.6'],
  'Põldmarja põik 7': ['601.2,631.6 615.2,553.6 526.5,547.1 522.7,601.8 536.7,600.7 536.8,628.7'],
  'Põldmarja põik 10': ['481.5,629.1 468.3,684 591,692.7 601.2,631.6 536.8,628.7'],
  'Põldmarja põik 8': ['371.3,614.5 385.4,617.5 481.5,629.1 468.3,684 341.7,676'],
  'Põldmarja põik 5': [
    '507.3,609.7 512.9,603.1 522.7,601.8 526.5,547.1 413.3,544.5 391.6,589.3 391.6,591.7 394.7,596.5'
  ],
  'Põldmarja põik 3': ['526.5,547.1 529.7,486.5 413.3,504.7 414.6,541.4 413.3,544.5'],
  'Põldmarja põik 1': [
    '367.1,468 374.7,474.5 382.8,470.5 399.8,484.8 407,492.9 413.3,504.7 529.7,486.5 529.7,477.2 493.6,455.8 455.5,449 426.4,449 395.7,454.8'
  ],
  'Põldmarja tee 45': ['305.1,491.5 324,487 351.1,475.1 382.6,501.4 386.2,512.5 386.7,534.3 290.6,538.2'],
  'Põldmarja põik 4': ['290.6,538.2 385.4,534.3 385.4,538.2 358.9,595 335.9,606.4 261.5,602.5'],
  'Põldmarja põik 6': [
    '261.5,602.5 335.9,606.4 357.3,596 356.4,599.8 356.8,602.5 367.5,613.2 371.3,614.5 319.1,722.9 240.6,668.1'
  ]
}
