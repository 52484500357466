import styled, { css } from 'styled-components'

export const PricesStyled = styled('div')(
  ({ theme }) => css`
    .title-block {
      display: flex;
      justify-content: center;
      //width: 50%;
      background: ${theme.colors.grey200};
      padding: ${theme.spacing(12, 2, 8)};
      .text-block {
        font-size: 20px;
        max-width: 1200px;
        width: 100%;
        display: block;
        padding: ${theme.spacing(0, 0)};
      }
      .text-description {
        display: block;
        padding: ${theme.spacing(2, 0)};
      }
      .title {
        line-height: 50px;
        text-align: center;
      }
      ${theme.breakpoints.down('sm')} {
        padding-left: ${theme.spacing(2)};
        width: 100%;
        justify-content: flex-start;
      }
    }
  `
)
