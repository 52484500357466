import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { Text } from '@controls/Text'
import { LocationStyled } from './Location.styles'
import locationImg from './location-map.svg'

export const Location = (): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <LocationStyled container>
      <Grid item xs={12} md={6.5}>
        <Box pt={4} px={2}>
          <img src={locationImg} alt="location-image" width="100%" loading="lazy" />
        </Box>
      </Grid>
      <Grid item xs={12} md={4.5} px={2}>
        <Text size={{ xs: 'size-34', sm: 'size-46' }} mb={2} component="div" pt={{ xs: 0, md: 30 }} ml={-0.3}>
          {t('pages.landing.location.title')}
        </Text>
        <Text size={{ xs: 'size-18', sm: 'size-24' }} component="div" textAlign="justify" whiteSpace="pre-wrap">
          {t('pages.landing.location.description')}
        </Text>
        <Text size={{ xs: 'size-18', sm: 'size-20' }} component="div" textAlign="justify" whiteSpace="pre-wrap">
          {t('pages.landing.location.description2')}
        </Text>
      </Grid>
    </LocationStyled>
  )
}
