import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Text } from '@controls/Text'
import { MainDescriptionStyled } from './MainDescription.styles'

export const MainDescription = (): React.ReactElement => {
  const { t } = useTranslation()

  const statisticItems = [
    { amount: 96, label: t('pages.landing.about.main_description.statistics.private_houses') },
    { amount: 150, label: t('pages.landing.about.main_description.statistics.row_houses') },
    { amount: 8, label: t('pages.landing.about.main_description.statistics.semi_detached_houses') }
  ]

  return (
    <MainDescriptionStyled>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid item xs={12} md={6}>
          <Text size={{ xs: 'size-34', sm: 'size-46' }} weight="bold" mb={3} component="div">
            {t('pages.landing.about.main_description.title')}
          </Text>
          <Text size={{ xs: 'size-34', sm: 'size-46' }} weight="light" lineHeight="1.25em">
            {t('pages.landing.about.main_description.subtitle')}
          </Text>
        </Grid>
        <Grid item xs={12} md={6}>
          <Text
            size={{ xs: 'size-14', sm: 'size-18' }}
            weight="regular"
            lineHeight="1.75em"
            component="div"
            mb={2}
            whiteSpace="pre-wrap"
            textAlign="justify"
          >
            <Trans i18nKey="pages.landing.about.main_description.description" />
          </Text>
          <Grid container mt={6}>
            {statisticItems.map((item) => (
              <Grid key={item.label} item xs={4}>
                <Text size={{ xs: 'size-40', sm: 'size-55' }} component="div" color="brown" lineHeight="1em">
                  {item.amount}
                </Text>
                <Text
                  component="div"
                  size={{ xs: 'size-14', sm: 'size-18' }}
                  weight="regular"
                  color="brown"
                  pr={4}
                  display="block"
                  lineHeight="1.25em"
                >
                  {item.label}
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </MainDescriptionStyled>
  )
}
