import styled, { css } from 'styled-components'
import heroBg from './hero-bg.webp'

export const HeroStyled = styled('div')(
  ({ theme }) => css`
    position: static;
    //background-color: black;
    background-image: url(${heroBg});
    background-position: center center;
    background-size: cover;
    background-blend-mode: multiply;
    object-fit: cover;
    height: 100vh;
    width: 100%;
    color: ${theme.colors.white};

    .bg-overlay {
      ${theme.mixins.engulfAbsolute}
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: calc(100% / 3);
      background: ${theme.mixins.opacity(theme.colors.black, 0.3)};

      .hero-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        padding: ${theme.spacing(7)};

        img {
          height: ${theme.spacing(8)};

          ${theme.breakpoints.down('md')} {
            height: ${theme.spacing(5)};
          }
        }
      }

      .hero-center {
        ${theme.mixins.flexCenter}
        row-gap: ${theme.spacing(2)};
        max-width: 800px;
        flex-direction: column;
        text-align: center;

        .title {
          font-weight: 400;
        }

        .subtitle {
          font-weight: 500;
        }
      }
    }
  `
)
