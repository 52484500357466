import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '../Landing.styles'

export const MainDescriptionStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    color: ${theme.colors.grey800};
    background: ${theme.colors.grey200};
    padding-top: ${theme.spacing(20)};
    padding-bottom: ${theme.spacing(18)};
  `
)
