import styled, { css } from 'styled-components'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'

export const PriceMapStyled = styled('div')(
  ({ theme }) => css`
    position: relative;
    display: flex;

    .polygons-container {
      ${theme.mixins.engulfAbsolute}
    }
  `
)

export const PolygonTooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(
  ({ theme }) => css`
    & .${tooltipClasses.tooltip} {
      padding: ${theme.spacing(2)};
      border-radius: 0;
      background: ${theme.mixins.opacity(theme.colors.black, 0.75)};
    }

    & .${tooltipClasses.arrow} {
      color: ${theme.mixins.opacity(theme.colors.black, 0.75)};
    }
  `
)
