import styled, { css } from 'styled-components'

export const PriceTableStyled = styled('div')(
  ({ theme }) => css`
    background: ${theme.colors.grey200};
    display: flex;
    justify-content: center;
    padding: ${theme.spacing(6, 4, 0, 4)};

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(0)};
    }

    .MuiTableContainer-root {
      width: 100%;
      max-height: 800px;

      thead {
        background: ${theme.colors.grey200};
      }

      tbody {
        max-height: 800px;
        background: ${theme.colors.white};

        tr.disabled {
          td {
            color: ${theme.colors.grey700};
          }
        }

        tr:not(.disabled):hover {
          background: ${theme.mixins.opacity(theme.colors.brown, 0.1)};
        }
      }

      th,
      td {
        font-size: ${theme.global.typography.size['size-14']};
      }

      th {
        border: none;
      }

      td {
        border-bottom: 2px solid ${theme.colors.grey200};
      }

      .col-more-info {
        text-align: right;

        button {
          font-size: ${theme.global.typography.size['size-14']};
          padding: ${theme.spacing(0.75, 1.5)};
          width: 100px;
        }
      }

      .col-price {
        text-align: right;

        &:not(th) {
          color: ${theme.colors.brown};
          font-weight: ${theme.typography.fontWeightBold};
        }
      }
    }
  `
)
