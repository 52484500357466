import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { sortBookingItemsByAddressAndApartmentNumber, useGetBookingItems } from '@api/endpoints/buildings/houses'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { Text } from '@controls/Text'
import { PriceMap } from './PriceMap'
import { PriceTable } from './PriceTable'
import { PricesStyled } from './Prices.styles'

export const Prices: React.FC = () => {
  const { t } = useTranslation()
  const defaultErrorHandler = useDefaultErrorHandler()

  const getBookingItems = useGetBookingItems({ onError: defaultErrorHandler })

  // TODO: There should be used `sortBookingItemsByAddressAndApartmentNumberAndFilterDisabled` but in this case disabled houses are moving neighbors on the map for some reason (may be there is a strict sequence is expected)
  const bookingItems = React.useMemo(
    () => (getBookingItems.data ? sortBookingItemsByAddressAndApartmentNumber(getBookingItems.data) : []),
    [getBookingItems.data]
  )

  return (
    <PricesStyled>
      <div className="title-block">
        <div className="text-block">
          <Text size={{ xs: 'size-34', sm: 'size-46' }} mb={3} component="div" ml={-0.3}>
            {t('pages.landing.prices.title')}
          </Text>
          <Text
            size={{ xs: 'size-18', sm: 'size-24' }}
            className="text-description"
            whiteSpace="pre-wrap"
            textAlign="justify"
          >
            {t('pages.landing.prices.description')}
          </Text>
          <Text
            className="text-description"
            whiteSpace="pre-wrap"
            size={{ xs: 'size-16', sm: 'size-18' }}
            textAlign="justify"
          >
            {t('pages.landing.prices.description2')}
          </Text>
        </div>
      </div>

      <PriceMap data={bookingItems} id="prices" />
      <PriceTable data={bookingItems} />
    </PricesStyled>
  )
}
