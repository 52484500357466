import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '../Landing.styles'

export const AmenitiesStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 0, 18)};

    .image-container {
      .landing-amenities-images {
        height: 100%;
      }

      .action-panel {
        .box {
          width: 100%;
          height: 100%;
          background: ${theme.colors.grey300};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  `
)
