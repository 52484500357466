import * as React from 'react'
import { Header } from '@layout/CustomerLayout/Header'
import { Advantages } from './Advantages'
import { Amenities } from './Amenities'
import { CompanyInfo } from './CompanyInfo'
import { Contact } from './Contact'
import { Families } from './Families'
import { Hero } from './Hero'
import { Houses } from './Houses'
import { LandingStyled } from './Landing.styles'
import { Location } from './Location'
import { MainDescription } from './MainDescription'
import { Prices } from './Prices'
import { ProjectTimeline } from './ProjectTimeline'

export const Landing: React.FC = () => {
  return (
    <LandingStyled>
      <Header bgType="light" />
      <Hero />
      <div id="about">
        <MainDescription />
        <Advantages />
        <Amenities />
        <Families />
      </div>
      <div id="location">
        <Location />
      </div>
      <div id="timeline">
        <ProjectTimeline />
      </div>
      <div id="houses">
        <Houses />
      </div>
      <div>
        <Prices />
      </div>
      <div id="contact">
        <CompanyInfo />
        <Contact />
      </div>
    </LandingStyled>
  )
}
