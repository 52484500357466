import { useTranslation } from 'react-i18next'
import { Text } from '@controls/Text'
import viimsiLogoImg from '@icons/viimsi-logo-compact.svg'
import { FamiliesStyled } from './Families.styles'

export const Families = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <FamiliesStyled>
      <div className="bg-overlay" />
      <img src={viimsiLogoImg} alt="" className="viimsi-logo" loading="lazy" />
      <div className="text-container">
        <Text size="size-46" mb={4} component="div" className="title" ml={-0.3}>
          {t('pages.landing.about.families.title')}
        </Text>
        <Text size="size-24" component="div" mb={3} weight="regular" textAlign="justify" whiteSpace="pre-wrap">
          {t('pages.landing.about.families.description1')}
        </Text>
        {/* <Text size="size-46">{t('pages.landing.about.families.area_size')}</Text> */}
        <Text
          size="size-18"
          component="div"
          lineHeight={1.8}
          weight="regular"
          whiteSpace="pre-wrap"
          textAlign="justify"
        >
          {t('pages.landing.about.families.description2')}
        </Text>
      </div>
    </FamiliesStyled>
  )
}
