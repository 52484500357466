import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Text } from '@controls/Text'
import { AdvantagesStyled } from './Advantages.styles'
import bricksImg from './bricks.svg'
import communicationsImg from './communications.svg'
import energyImg from './energy.svg'
import exteriorImg from './exterior-renders.jpeg'
import heatingImg from './heating.svg'
import locationImg from './location.svg'
import optionsImg from './options.svg'
import parksImg from './parks.svg'
import ventilationImg from './ventilation.svg'

export const Advantages = (): React.ReactElement => {
  const { t } = useTranslation()

  const advantageItems = [
    { icon: energyImg, label: t('pages.landing.about.advantages.energy') },
    { icon: heatingImg, label: t('pages.landing.about.advantages.heating') },
    { icon: ventilationImg, label: t('pages.landing.about.advantages.ventilation') },
    { icon: bricksImg, label: t('pages.landing.about.advantages.housematerial') },
    { icon: communicationsImg, label: t('pages.landing.about.advantages.communications') },
    {
      icon: parksImg,
      label: t('pages.landing.about.advantages.parks')
    },
    {
      icon: locationImg,
      label: t('pages.landing.about.advantages.location')
    },
    {
      icon: optionsImg,
      label: t('pages.landing.about.advantages.options')
    }
  ]

  return (
    <AdvantagesStyled>
      <Text size={{ xs: 'size-34', sm: 'size-46' }} lineHeight="1.25em" component="div" mb={6} ml={-0.3}>
        {t('pages.landing.about.advantages.title')}
      </Text>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid item xs={12} md={6}>
          <Grid container columnSpacing={4} rowSpacing={4}>
            {advantageItems.map((item, i) => (
              <Grid item xs={12} sm={6} md={12} className="advantage-item" key={i}>
                <img src={item.icon} alt="advantage-icon" />
                <Text size="size-16" lineHeight="1.25em" weight="regular" textAlign="justify">
                  {item.label}
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className="image-container">
          <img src={exteriorImg} height="75%" alt="exterior" loading="lazy" />
        </Grid>
      </Grid>
    </AdvantagesStyled>
  )
}
