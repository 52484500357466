import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@controls/Text'
import viimsiLogoImg from '@icons/viimsi-logo-compact.svg'
import { ProjectTimelineStyled } from './ProjectTimeline.styles'

export const ProjectTimeline = (): React.ReactElement => {
  const { t } = useTranslation()

  const timelineItems = [
    {
      label: t('pages.landing.timeline.checkpoint1'),
      date: t('pages.landing.timeline.checkpoint1_date'),
      icon: viimsiLogoImg
    },
    {
      label: t('pages.landing.timeline.checkpoint2'),
      date: t('pages.landing.timeline.checkpoint2_date'),
      icon: viimsiLogoImg
    },
    {
      label: t('pages.landing.timeline.checkpoint3'),
      date: t('pages.landing.timeline.checkpoint3_date'),
      icon: viimsiLogoImg
    },
    {
      label: t('pages.landing.timeline.checkpoint4'),
      date: t('pages.landing.timeline.checkpoint4_date'),
      icon: viimsiLogoImg
    },
    {
      label: t('pages.landing.timeline.checkpoint5'),
      date: t('pages.landing.timeline.checkpoint5_date'),
      icon: viimsiLogoImg
    },
    { label: t('pages.landing.timeline.checkpoint6'), date: t('pages.landing.timeline.checkpoint6_date') }
  ]

  return (
    <ProjectTimelineStyled>
      <Text size={{ xs: 'size-34', sm: 'size-46' }} mb={{ xs: 16, md: 12 }} className="content-headline">
        {t('pages.landing.timeline.title')}
      </Text>
      <div className="timeline-items">
        {timelineItems.map((item) => (
          <div className="timeline-item" key={item.label}>
            <Text
              className="timeline-label"
              color="brown"
              weight="regular"
              size={{ xs: 'size-14', sm: 'size-16' }}
              textAlign="center"
              whiteSpace="pre-wrap"
            >
              {item.label}
            </Text>
            <div className="timeline-marker">{item.icon && <img src={item.icon} alt="done" />}</div>
            <Text
              className="timeline-date"
              size={{ xs: 'size-12', sm: 'size-14' }}
              whiteSpace="pre-wrap"
              textAlign="center"
              weight="regular"
            >
              {item.date}
            </Text>
          </div>
        ))}
      </div>
    </ProjectTimelineStyled>
  )
}
