import { useTranslation } from 'react-i18next'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { Box } from '@mui/material'
import { Button } from '@controls/Button'
import { Text } from '@controls/Text'
import { HeroStyled } from './Hero.styles'

export const Hero = (): React.ReactElement => {
  const { t } = useTranslation()

  const onRegisterInterestClick: HtmlMouseButtonEventHandler = (e) => {
    e.preventDefault()
    e.currentTarget.blur()
    document.getElementById('landing_contact_us')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <HeroStyled>
      <div className="bg-overlay">
        <Box flex={1} width="100%">
          <div className="hero-header">
            {/*
            <img src={viimsiLogo} alt="logo" />
            <LanguageDropdown color="white" />
            */}
          </div>
        </Box>
        <Box className="hero-center" flex={1}>
          <Text size={{ xs: 'size-40', sm: 'size-60' }} lineHeight="1em" className="title">
            {t('pages.landing.hero.title')}
          </Text>
          <Text size={{ xs: 'size-18', sm: 'size-24' }} my={3} maxWidth={550} className="subtitle">
            {t('pages.landing.hero.subtitle')}
          </Text>
          <Button id="btnJumpToContactForm" color="white" onClick={onRegisterInterestClick}>
            {t('pages.landing.hero.button')}
          </Button>
        </Box>
        <Box flex={1} />
      </div>
    </HeroStyled>
  )
}
